<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <h1 class="mr-sm-4 header-tablepage">Repair Cause Detail</h1>
        <div class="title-tabs mt-3">Repair Cause</div>
        <div class="bg-white p-3">
          <b-row>
            <b-col cols="6">
              <InputText
                textFloat="Name"
                placeholder="Name"
                isRequired
                v-model="form.name"
                :v="$v.form.name"
                :isValidate="$v.form.name.$error"
              />
            </b-col>

            <b-col cols="12">
              <InputTextArea
                v-model="form.description"
                textFloat="Description"
                placeholder="Description"
                rows="3"
                class="mt-2"
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <FooterAction @submit="submit()" routePath="/setting/repair/repair-cause" />
  </div>
</template>
<script>
import OtherLoading from "@/components/loading/OtherLoading";
import { required } from "vuelidate/lib/validators";

export default {
  components: { OtherLoading },
  validations() {
    return {
      form: {
        name: { required },
      },
    };
  },
  data() {
    return {
      isLoading: false,
      form: {
        name: "",
        description: "",
      },
      id: this.$route.params.id,
    };
  },
  async created() {
    await this.getDetail();
  },
  methods: {
    async getDetail() {
      if (this.id == "0") {
        return;
      }
      this.$bus.$emit("showLoading");
      const res = await this.axios(`/repair/RepairCause/${this.id}`);
      this.form = res.data.detail;
      this.$bus.$emit("hideLoading");
    },
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$bus.$emit("showLoading");
      if (this.id !== "0") {
        const res = await this.axios.put(
          "/repair/UpdateRepairCause",
          this.form
        );
        if (res.data.result) {
          this.successAlert().then(() => {
            this.$router.push("/setting/repair/repair-cause", this.form);
          });
        } else {
          this.errorAlert(res.data.message);
        }
      } else {
        const res = await this.axios.post(
          "/repair/CreateRepairCause",
          this.form
        );
        if (res.data.result) {
          this.successAlert().then(() => {
            this.$router.push("/setting/repair/repair-cause", this.form);
          });
        } else {
          this.errorAlert(res.data.message);
        }
      }

      this.$bus.$emit("hideLoading");
    },
    handleStock(value, type) {
      // this.selectedReceiveIndex = index;
      // this.selectedReceive = value;
      // this.$refs[`${this.depth}-customerModal`].show();
    },
  },
};
</script>
<style lang="scss" scoped></style>
